/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';
import ngFileUpload from 'ng-file-upload';
import _ from 'lodash';
import {ApiConfig} from "../api/api";

const MODULE_NAME = "adn-shotter-helper";

angular.module(MODULE_NAME, [
  ngFileUpload
])

  .factory('adnShotter', function($http) {
    return {
      deleteScreenshot: function(screenshot) {
        return $http.get(ApiConfig.obtain().getVersionedUri(['adnshotter', 'deleteshot']), {
          params: _.assign({}, {format: 'json', concise: true, shotId: screenshot.idOfShot})
        }).then(_.iteratee('data'));
      },
      fetchAll: function() {
        return $http.get(ApiConfig.obtain().getVersionedUri(['adnshotter', 'fetchall']), {
          params: _.assign({}, {format: 'json', concise: true})
        }).then(_.iteratee('data'));
      },
      fetchScreenshots: function(lineItemId, creativeId) {
        return $http.get(ApiConfig.obtain().getVersionedUri(['adnshotter', 'results']), {
          params: _.assign({}, {format: 'json', concise: true, lineItemId: lineItemId, creativeId: creativeId})
        }).then(_.iteratee('data'));
      },
      takeShot: function(creativeId, lineItemId, url, device, bypassSave, headless) {
        return $http.get(ApiConfig.obtain().getVersionedUri(['adnshotter', 'takeshot']), {
          params: _.assign({}, {format: 'json', concise: true, url: url, creativeId: creativeId, lineItemId: lineItemId, bypassSave: bypassSave, headless: headless, device: device || 'DESKTOP'})
        }).then(_.iteratee('data'));
      }
    };
  });

export default MODULE_NAME;