/**
 * Copyright © 2025 Adnuntius AS.
 */
import * as _ from 'lodash';

export const ALT_IDS = {
  eCpm: 'grossECpm',
  vCpm: 'grossVCpm'
};

export const ADV_QUERY_FIELDS = {
  startDate: {
    name: 'Start Date',
    id: 'startDate',
    parentId: 'lineItem',
    isDate: true,
    unselected: true,
  },
  endDate: {
    name: 'End Date',
    id: 'endDate',
    parentId: 'lineItem',
    isDate: true,
    unselected: true
  },
  bids: {
    name: 'Available Traffic',
    id: 'bids',
    icon: 'fa-hand-paper-o',
    data: [],
    yAxis: 0,
    allowDecimals: false,
    isPercentage: false,
    tooltip: {
      valueDecimals: 0
    }
  },
  rateLimited: {
    name: 'Rate Limited',
    key: 'report.field.rateLimited',
    id: 'rateLimited',
    data: [],
    yAxis: 1,
    icon: 'fa-ban',
    unselected: true,
    allowDecimals: false,
  },
  frequencyCapped: {
    name: 'Frequency Capped',
    key: 'report.field.frequencyCapping',
    id: 'frequencyCapped',
    data: [],
    yAxis: 2,
    unselected: true,
    icon: 'fa-ban',
    allowDecimals: false,
  },
  belowFloor: {
    name: 'Bid Below Floor Price',
    key: 'report.field.belowFloor',
    id: 'belowFloor',
    data: [],
    yAxis: 3,
    unselected: true,
    icon: 'fa-ban',
    marketplaceOnly: true,
    allowDecimals: false,
  },
  losingBids: {
    name: 'Beaten in Auction',
    key: 'report.field.losingBids',
    id: 'losingBids',
    unselected: true,
    data: [],
    yAxis: 4,
    icon: 'fa-ban',
    allowDecimals: false,
  },
  impressions: {
    name: 'Impressions',
    key: 'report.field.impressions',
    id: 'impressions',
    icon: 'fa-picture-o',
    data: [],
    yAxis: 5,
    allowDecimals: false
  },
  deviceStorageAllowed: {
    name: 'Cookies Permitted',
    id: 'deviceStorageAllowed',
    data: [],
    yAxis: 6,
    unselected: true,
    icon: 'fa-database',
    gdprEnabledOnly: true,
    allowDecimals: false
  },
  rendered: {
    name: 'Rendered Impressions',
    key: 'report.field.rendered',
    id: 'rendered',
    icon: 'fa-picture-o',
    data: [],
    yAxis: 7,
    allowDecimals: false
  },
  renderedRate: {
    name: 'Rendered Rate',
    key: 'report.field.renderedRate',
    id: 'renderedRate',
    icon: 'fa-percent',
    data: [],
    yAxis: 8,
    unselected: true,
    allowDecimals: true
  },
  visibles: {
    name: 'Visibles',
    id: 'visibles',
    icon: 'fa-eye',
    data: [],
    yAxis: 9,
    availability: {'DIRECT': true},
    allowDecimals: false
  },
  visibility: {
    name: 'Visibility',
    id: 'visibility',
    icon: 'fa-eye',
    data: [],
    yAxis: 10,
    allowDecimals: true,
    isPercentage: true,
    singularOnly: true,
    availability: {'DIRECT': true},
    tooltip: {
      valueDecimals: 1
    }
  },
  viewables: {
    name: 'Viewables',
    id: 'viewables',
    icon: 'fa-desktop',
    data: [],
    yAxis: 11,
    allowDecimals: false
  },
  viewability: {
    name: 'Viewability',
    id: 'viewability',
    icon: 'fa-tv',
    data: [],
    yAxis: 12,
    allowDecimals: true,
    isPercentage: true,
    singularOnly: true,
    tooltip: {
      valueDecimals: 1
    }
  },
  uniqueUsers: {
    name: 'Unique Users',
    id: 'uniqueUsers',
    icon: 'fa-user',
    data: [],
    yAxis: 13,
    allowDecimals: false
  },
  impressionsPerUniqueUser: {
    name: 'Impressions per Unique',
    id: 'impressionsPerUniqueUser',
    icon: 'fa-user',
    data: [],
    yAxis: 14,
    allowDecimals: true,
    unselected: true,
    tooltip: {
      valueDecimals: 2
    }
  },
  clicks: {
    name: 'Clicks',
    id: 'clicks',
    data: [],
    abbr: true,
    yAxis: 15,
    icon: 'fa-hand-o-up',
    allowDecimals: false
  },
  conversions: {
    name: 'Conversions',
    id: 'conversions',
    icon: 'fa-check',
    data: [],
    yAxis: 16,
    allowDecimals: false,
    isPercentage: false,
    tooltip: {
      valueDecimals: 0
    }
  },
  ctr: {
    name: 'CTR',
    id: 'ctr',
    data: [],
    yAxis: 17,
    icon: 'fa-line-chart',
    abbr: true,
    unselected: true,
    isPercentage: true,
    allowDecimals: true,
    singularOnly: true,
    tooltip: {
      valueDecimals: 3
    }
  },
  eCpm: {
    name: 'eCPM',
    id: 'eCpm',
    altId: 'grossECpm',
    icon: 'fa-money',
    abbr: true,
    data: [],
    yAxis: 18,
    unselected: true,
    allowDecimals: true,
    tooltip: {
      valueDecimals: 2
    }
  },
  vCpm: {
    name: 'vCPM',
    id: 'vCpm',
    altId: 'grossVCpm',
    icon: 'fa-money',
    abbr: true,
    data: [],
    yAxis: 19,
    unselected: true,
    allowDecimals: true,
    tooltip: {
      valueDecimals: 2
    }
  },
  cpc: {
    name: 'CPC',
    id: 'cpc',
    altId: 'grossCpc',
    icon: 'fa-money',
    data: [],
    yAxis: 20,
    abbr: true,
    unselected: true,
    allowDecimals: true,
    tooltip: {
      valueDecimals: 2
    }
  },
  cost: {
    name: 'Cost',
    id: 'cost',
    data: [],
    yAxis: 21,
    abbr: true,
    icon: 'fa-money',
    allowDecimals: true,
    tooltip: {
      valueDecimals: 2
    }
  },
  fee: {
    name: 'Fees',
    id: 'fee',
    data: [],
    yAxis: 22,
    abbr: true,
    icon: 'fa-money',
    allowDecimals: true,
    unselected: true,
    tooltip: {
      valueDecimals: 2
    }
  },
  techFee: {
    name: 'Tech Fee',
    id: 'techFee',
    data: [],
    yAxis: 23,
    unselected: true,
    icon: 'fa-money',
    allowDecimals: true,
    marketplaceOnly: true,
    tooltip: {
      valueDecimals: 2
    }
  },
  creativeTagFee: {
    name: 'Creative Tag Fee',
    id: 'creativeTagFee',
    data: [],
    yAxis: 24,
    unselected: true,
    icon: 'fa-money',
    allowDecimals: true,
    marketplaceOnly: true,
    tooltip: {
      valueDecimals: 2
    }
  },
  creativeFormatFee: {
    name: 'Creative Format Fee',
    id: 'creativeFormatFee',
    data: [],
    yAxis: 25,
    unselected: true,
    icon: 'fa-money',
    allowDecimals: true,
    marketplaceOnly: true,
    tooltip: {
      valueDecimals: 2
    }
  },
  dataFee: {
    name: 'Data Fee',
    id: 'dataFee',
    data: [],
    yAxis: 26,
    unselected: true,
    icon: 'fa-money',
    allowDecimals: true,
    marketplaceOnly: true,
    tooltip: {
      valueDecimals: 2
    }
  },
  supplementaryFee: {
    name: 'Supplementary Fee',
    id: 'supplementaryFee',
    data: [],
    yAxis: 27,
    unselected: true,
    icon: 'fa-money',
    allowDecimals: true,
    marketplaceOnly: true,
    tooltip: {
      valueDecimals: 2
    }
  },
  totalCost: {
    name: 'Total Cost',
    id: 'totalCost',
    data: [],
    yAxis: 28,
    icon: 'fa-money',
    allowDecimals: true,
    unselected: true,
    hideIfMatched: 'cost',
    tooltip: {
      valueDecimals: 2
    }
  },
  fullyViewables: {
    name: 'Fully Viewables',
    id: 'fullyViewables',
    icon: 'fa-eye',
    data: [],
    unselected: true,
    user: 'adnuntius@adnuntius.com',
    yAxis: 29,
    allowDecimals: false
  },
  averageRelativeSize: {
    name: 'Average Relative Size',
    id: 'averageRelativeSize',
    icon: 'fa-arrows',
    user: 'adnuntius@adnuntius.com',
    isPercentage: true,
    unselected: true,
    data: [],
    yAxis: 30,
    allowDecimals: false
  },
  customFields: {
    name: 'Custom Fields',
    id: 'customFields',
    yAxis: -1
  }
};
export const LINE_ITEM_QUERY_FIELDS = _.cloneDeep(ADV_QUERY_FIELDS);
delete ADV_QUERY_FIELDS.startDate;
delete ADV_QUERY_FIELDS.endDate;
export const ADV_FIELDS = _.assign({}, ADV_QUERY_FIELDS);
export const TARGETING_FIELDS = _.filter(ADV_FIELDS, function (field) {
  return ['impressions', 'rendered', 'visibles', 'viewables', 'clicks'].indexOf(field.id) > -1;
});

export const PUB_QUERY_FIELDS = {
  impressionRequests: {
    name: 'Requests',
    id: 'impressionRequests',
    icon: 'fa-square-o',
    data: [],
    yAxis: 0,
    allowDecimals: false
  },
  deviceStorageAllowed: {
    name: 'Cookies Permitted',
    id: 'deviceStorageAllowed',
    data: [],
    yAxis: 1,
    icon: 'fa-database',
    allowDecimals: false
  },
  positiveImpressionRequests: {
    name: 'Pos. Requests',
    id: 'positiveImpressionRequests',
    icon: 'fa-check-square-o',
    data: [],
    yAxis: 2,
    allowDecimals: false
  },
  internalBidsRejected: {
    name: 'Rejected Bids',
    id: 'internalBidsRejected',
    icon: 'fa-times',
    data: [],
    yAxis: 3,
    allowDecimals: false
  },
  matchRate: {
    name: 'Match Rate',
    id: 'matchRate',
    icon: 'fa-check-square',
    data: [],
    yAxis: 4,
    allowDecimals: true,
    isPercentage: true,
    singularOnly: true,
    tooltip: {
      valueDecimals: 1
    }
  },
  impressions: {
    name: 'Impressions',
    key: 'report.field.impressions',
    id: 'impressions',
    icon: 'fa-picture-o',
    data: [],
    yAxis: 5,
    allowDecimals: false
  },
  rendered: {
    name: 'Rendered Impressions',
    key: 'report.field.rendered',
    id: 'rendered',
    icon: 'fa-picture-o',
    data: [],
    yAxis: 6,
    allowDecimals: false
  },
  visibles: {
    name: 'Visibles',
    id: 'visibles',
    icon: 'fa-eye',
    data: [],
    yAxis: 7,
    availability: {'DIRECT': true},
    allowDecimals: false
  },
  visibility: {
    name: 'Visibility',
    id: 'visibility',
    icon: 'fa-eye',
    data: [],
    yAxis: 8,
    allowDecimals: true,
    isPercentage: true,
    singularOnly: true,
    availability: {'DIRECT': true},
    tooltip: {
      valueDecimals: 1
    }
  },
  viewables: {
    name: 'Viewables',
    id: 'viewables',
    icon: 'fa-desktop',
    data: [],
    yAxis: 9,
    allowDecimals: false
  },
  viewability: {
    name: 'Viewability',
    id: 'viewability',
    icon: 'fa-tv',
    data: [],
    yAxis: 10,
    allowDecimals: true,
    isPercentage: true,
    singularOnly: true,
    tooltip: {
      valueDecimals: 1
    }
  },
  uniqueUsers: {
    name: 'Unique Users',
    id: 'uniqueUsers',
    icon: 'fa-user',
    data: [],
    yAxis: 11,
    allowDecimals: false
  },
  impressionsPerUniqueUser: {
    name: 'Impressions per Unique',
    id: 'impressionsPerUniqueUser',
    icon: 'fa-user',
    data: [],
    yAxis: 12,
    allowDecimals: true,
    unselected: true,
    tooltip: {
      valueDecimals: 2
    }
  },
  clicks: {
    name: 'Clicks',
    id: 'clicks',
    icon: 'fa-hand-o-up',
    data: [],
    yAxis: 13,
    abbr: true,
    allowDecimals: false
  },
  ctr: {
    name: 'CTR',
    id: 'ctr',
    data: [],
    allowDecimals: true,
    isPercentage: true,
    icon: 'fa-line-chart',
    yAxis: 14,
    abbr: true,
    singularOnly: true,
    tooltip: {
      valueDecimals: 3
    }
  },
  eCpm: {
    name: 'eCPM',
    id: 'eCpm',
    data: [],
    icon: 'fa-money',
    yAxis: 15,
    abbr: true,
    allowDecimals: true,
    tooltip: {
      valueDecimals: 2
    }
  },
  cost: {
    name: 'Earnings',
    id: 'cost',
    data: [],
    yAxis: 16,
    tKey: 'earnings',
    icon: 'fa-money',
    allowDecimals: true,
    tooltip: {
      valueDecimals: 2
    }
  },
  revenueShare: {
    name: 'Revenue Share',
    id: 'revenueShare',
    data: [],
    yAxis: 17,
    tKey: 'revenueShare',
    icon: 'fa-money',
    allowDecimals: true,
    tooltip: {
      valueDecimals: 2
    }
  },
  customFields: {
    name: 'Custom Fields',
    id: 'customFields',
    yAxis: -1
  }
};

export const PUB_FIELDS = _.assign({}, PUB_QUERY_FIELDS);

export const BURN_RATE_FIELDS = {
  burnRate: {
    name: 'Burn Rate (%)',
    id: 'burnRate',
    data: [],
    yAxis: 0,
    allowDecimals: false,
    isPercentage: true,
    tooltip: {
      valueDecimals: 0
    }
  },
  rateLimiting: {
    name: 'Rate Limiting (%)',
    id: 'rateLimiting',
    data: [],
    yAxis: 0,
    allowDecimals: false,
    isPercentage: true,
    tooltip: {
      valueDecimals: 0
    }
  },
  frequencyCapping: {
    name: 'Frequency Capping (%)',
    id: 'frequencyCapping',
    data: [],
    yAxis: 0,
    allowDecimals: false,
    isPercentage: true,
    tooltip: {
      valueDecimals: 0
    }
  }
};

export const ARTICLE_FIELDS = {
  impressions: {
    name: 'Impressions',
    key: 'report.field.impressions',
    id: 'impressions',
    icon: 'fa-picture-o',
    data: [],
    yAxis: 1,
    allowDecimals: false
  },
  rendered: {
    name: 'Rendered Impressions',
    key: 'report.field.rendered',
    id: 'rendered',
    icon: 'fa-picture-o',
    data: [],
    yAxis: 2,
    allowDecimals: false
  },
  viewables: {
    name: 'Viewables',
    id: 'viewables',
    icon: 'fa-desktop',
    data: [],
    yAxis: 3,
    allowDecimals: false
  },
  clicks: {
    name: 'Clicks',
    id: 'clicks',
    data: [],
    abbr: true,
    yAxis: 4,
    icon: 'fa-hand-o-up',
    allowDecimals: false
  },
  ctr: {
    name: 'CTR',
    id: 'ctr',
    data: [],
    yAxis: 5,
    icon: 'fa-line-chart',
    abbr: true,
    isPercentage: true,
    allowDecimals: true,
    singularOnly: true,
    tooltip: {
      valueDecimals: 3
    }
  },
  matchScore: {
    name: 'Match Score',
    id: 'matchScore',
    data: [],
    yAxis: 6,
    icon: 'fa-star'
  }
};

export const CDN_STATS_FIELDS = {
  traffic: {
    name: 'Traffic',
    id: 'traffic',
    data: [],
    yAxis: 0,
    allowDecimals: true,
    isPercentage: true,
    tooltip: {
      valueDecimals: 2
    }
  }
};

export const EXTERNAL_AD_UNIT_QUERY_FIELDS = {
  requests: {
    name: 'Requests',
    id: 'requests',
    icon: 'fa-square-o',
    data: [],
    yAxis: 0,
    allowDecimals: false
  },
  wins: {
    name: 'Wins',
    id: 'wins',
    icon: 'fa-thumbs-o-up',
    abbr: true,
    data: [],
    yAxis: 1,
    allowDecimals: false
  },
  winningBid: {
    name: 'Winning Bids',
    id: 'winningBid',
    icon: 'fa-money',
    data: [],
    yAxis: 2,
    allowDecimals: true,
    tooltip: {
      valueDecimals: 2
    }
  },
  rendered: {
    name: 'Rendered Impressions',
    key: 'report.field.rendered',
    id: 'rendered',
    icon: 'fa-picture-o',
    data: [],
    yAxis: 3,
    allowDecimals: false
  },
  cost: {
    name: 'Cost',
    id: 'cost',
    icon: 'fa-money',
    data: [],
    abbr: true,
    yAxis: 4,
    allowDecimals: true
  },
  losses: {
    name: 'Losses',
    id: 'losses',
    icon: 'fa-thumbs-o-down',
    data: [],
    yAxis: 5,
    allowDecimals: false
  },
  losingBid: {
    name: 'Losing Bids',
    id: 'losingBid',
    icon: 'fa-money',
    data: [],
    yAxis: 6,
    allowDecimals: true,
    tooltip: {
      valueDecimals: 2
    }
  },
  noBids: {
    name: 'No Bids',
    id: 'noBids',
    icon: 'fa-tv',
    data: [],
    yAxis: 7,
    allowDecimals: false
  },
  highestWinningCPM: {
    name: 'Highest Winning CPM',
    id: 'highestWinningCPM',
    icon: 'fa-money',
    data: [],
    yAxis: 8,
    allowDecimals: true,
    singularOnly: true,
    toTheMax: true,
    tooltip: {
      valueDecimals: 2
    }
  },
  passbacks: {
    name: 'Passbacks',
    id: 'passbacks',
    icon: 'fa-exchange',
    data: [],
    yAxis: 9,
    allowDecimals: false
  },
  timeouts: {
    name: 'Timeouts',
    id: 'timeouts',
    icon: 'fa-clock-o',
    data: [],
    yAxis: 10,
    allowDecimals: false
  },
  errors: {
    name: 'Errors',
    id: 'errors',
    icon: 'fa-exclamation-triangle',
    data: [],
    yAxis: 11,
    allowDecimals: false
  }
};

export const COLLECT_QUERY_FIELDS = {
  profileUpdates: {
    name: 'Profile Updates',
    id: 'profileUpdates',
    icon: 'fa-picture-o',
    data: [],
    yAxis: 0,
    allowDecimals: false
  },
  uniqueUsers: {
    name: 'Unique Profiles',
    id: 'uniqueProfiles',
    icon: 'fa-user',
    data: [],
    yAxis: 1,
    allowDecimals: false
  },
  pageViews: {
    name: 'Page Views',
    id: 'pageViews',
    icon: 'fa-tv',
    data: [],
    yAxis: 2,
    allowDecimals: false
  }
};
export const COLLECT_FIELDS = _.assign({}, COLLECT_QUERY_FIELDS);

export const SEGMENT_QUERY_FIELDS = {
  addedMembers: {
    name: 'Added Members',
    id: 'addedMembers',
    icon: 'fa-user-plus',
    data: [],
    yAxis: 0,
    singularOnly: true,
    allowDecimals: false
  },
  removedMembers: {
    name: 'Removed Members',
    id: 'removedMembers',
    icon: 'fa-user-times',
    data: [],
    yAxis: 1,
    singularOnly: true,
    allowDecimals: false
  }
};
export const SEGMENT_FIELDS = _.assign({}, SEGMENT_QUERY_FIELDS);

export const ADN_CHART_STAT_TYPES = {
  impression: {
    id: 'impression'
  },
  rendered: {
    id: 'rendered'
  },
  visible: {
    id: 'visible'
  },
  viewable: {
    id: 'viewable'
  },
  click: {
    id: 'click'
  }
};

export const ADN_CHART_COUNT_TYPES = {
  singular: {
    id: 'singular'
  },
  cumulative: {
    id: 'cumulative'
  }
};

export const ADN_CHART_Y_AXIS_OPTIONS = {
  shared: {
    id: 'shared'
  },
  multiple: {
    id: 'multiple'
  }
};
