/**
 * Copyright © 2025 Adnuntius AS.
 */
import angular from 'angular';

import assetHelper from '../../../util/asset-helper';
import {AdServerConfig} from "../../api";
import _ from 'lodash';

const MODULE_NAME = "tcf";

angular.module(MODULE_NAME, [
  assetHelper
])

  .factory('TCF', function($http) {
    return {
      checkTcf: function(gdpr, consentString) {
        const params = {};
        if (gdpr === 'GDPR_ON') {
          params.gdpr = 1;
        } else if (gdpr === 'GDPR_OFF') {
          params.gdpr = 0;
        } else if (gdpr !== 'GDPR_AD_UNIT') {
          params.auId = gdpr;
        }
        if (consentString) {
          params.consentString = consentString;
        }
        const uri = AdServerConfig.obtain().getUri(['consent']);
        return $http.get(uri, {params: params}).then(function(results) {
          const text = results.data;

          if (text.indexOf("TCF Consent") > -1) {
            try {
              const countryMatch = text.match(/Country:\s*([A-Z]{2})/);
              const countryCode = countryMatch ? countryMatch[1] : null;

              const lines = text.split("\n");
              const purposeNumbers = [];

              lines.forEach(line => {
                if (line.includes("TCF_PURPOSE_")) {
                  let number = line.split("TCF_PURPOSE_")[1];
                  if (number) {
                    purposeNumbers.push(number.trim());
                  }
                }
              });

              const gdprValueMatch = text.match(/GDPR:\s*(true|false)/);
              const gdprValue = gdprValueMatch ? gdprValueMatch[1] : null;

              const ipMatch = text.match(/IP:\s*(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})/);
              const ipAddress = ipMatch ? ipMatch[1] : null;
              return {
                country: countryCode,
                gdpr: gdprValue,
                tcfPurposes: purposeNumbers,
                tcfPurposesString: purposeNumbers.join(", "),
                ipAddress: ipAddress
              };
            } catch (e) {
              return text;
            }
          }
          return text;
        }).catch(function(error) {
          return _.get(error.data, ['message']) || error.data;
        });
      }
    };
  });

export default MODULE_NAME;