/*
 * Copyright © 2025 Adnuntius AS.
 */

import angular from 'angular';
import _ from 'lodash';
import ngResource from 'angular-resource';
import {OBJECT_TYPE, ObjectTypeFinder} from "../../../app/common/constants/object-type";
import {OBJECT_STATE} from "./resources-constants";

import {AdServerConfig, ApiConfig, DataServerConfig} from '../api';
import userProfileSaver from '../../session/user-profile-saver';
import apiKey from './resource/api-key';
import adunit from './resource/adunit';
import adunittag from './resource/adunittag';
import advertiser from './resource/advertiser';
import asset from './resource/asset/asset';
import blocklist from './resource/blocklist';
import cdnAsset from './resource/asset/cdn-asset';
import contextServiceConfiguration from './resource/context-service-configuration';
import creative from './resource/creative';
import libraryCreative from './resource/library-creative';
import emailTranslation from './resource/email-translation';
import creativesPreview from './resource/creative-preview';
import customEvent from './resource/custom-event';
import dataExport from './resource/data-export';
import earningsAccount from './resource/earnings-account';
import externalAdUnit from './resource/externaladunit';
import externalDemandSource from './resource/external-demand-source';
import folio from './resource/folio';
import layout from './resource/layout';
import invoiceTranslation from './resource/invoice-translation';
import layoutInclude from './resource/layout-include';
import lineItem from './resource/line-item';
import coupon from './resource/coupon';
import networkForecast from './resource/network-forecast';
import firstPartyAudience from './resource/first-party-audience';
import targetingTemplate from './resource/targeting-template';
import networkData from './resource/network-data';
import note from './resource/note';
import tcf from './resource/tcf';
import publisherInfo from './resource/publisher-info';
import notification from './resource/notification';
import notificationPref from './resource/notification-preference';
import preview from './resource/preview';
import product from './resource/product';
import marketplaceProduct from './resource/marketplace-product';
import reportComponent from './resource/report-component';
import executionResource from './resource/execution-resources/execution-resource';
import referenceData from './resource/asset/reference-data';
import reportSchedule from './resource/report-schedule';
import reportTemplate from './resource/report-template';
import reportTranslation from './resource/report-translation';
import order from './resource/order';
import payment from './resource/payment';
import mapping from './resource/field-mapping';
import creativeSet from './resource/creative-set';
import network from './resource/network';
import networkProfile from './resource/network-profile';
import site from './resource/site';
import bids from './resource/bids';
import siteGroup from './resource/site-group';
import ruleset from './resource/ruleset';
import thirdPartyAudience from './resource/third-party-audience';
import trigger from './resource/trigger';
import segment from './resource/segment';
import folder from './resource/folder';
import role from './resource/role';
import team from './resource/team';
import teamGroup from './resource/team-group';
import user from './resource/user';
import userProfile from './resource/user-profile';
import userSegmentList from './resource/user-segment-list';
import workspace from './resource/workspace';
import visitorProfileFields from './resource/visitor-profile-fields';
import {HttpHelper} from "../../util/http-helper";

const MODULE_NAME = "resources";

angular.module(MODULE_NAME, [
  ngResource,
  userProfileSaver,
  adunit,
  adunittag,
  advertiser,
  asset,
  apiKey,
  bids,
  blocklist,
  cdnAsset,
  contextServiceConfiguration,
  coupon,
  creative,
  firstPartyAudience,
  libraryCreative,
  creativesPreview,
  customEvent,
  publisherInfo,
  dataExport,
  earningsAccount,
  folio,
  teamGroup,
  tcf,
  emailTranslation,
  executionResource,
  externalAdUnit,
  externalDemandSource,
  layout,
  layoutInclude,
  lineItem,
  marketplaceProduct,
  mapping,
  network,
  networkForecast,
  networkProfile,
  targetingTemplate,
  networkData,
  note,
  notification,
  notificationPref,
  order,
  payment,
  creativeSet,
  preview,
  product,
  referenceData,
  reportComponent,
  reportSchedule,
  reportTemplate,
  reportTranslation,
  ruleset,
  site,
  team,
  thirdPartyAudience,
  trigger,
  invoiceTranslation,
  segment,
  folder,
  role,
  user,
  userProfile,
  userSegmentList,
  workspace,
  siteGroup,
  visitorProfileFields
])

  .factory('adnResource', function($resource, $q, $http, $timeout, $state, $injector) {
    return function(pathSettings, defaultParams, actions, resourceName) {
      let relativePath = pathSettings.uri || pathSettings;

      let uri = ApiConfig.obtain().getVersionedUri(relativePath);
      if (pathSettings.adServer) {
        uri = AdServerConfig.obtain().getUri(relativePath);
      } else if (pathSettings.dataServer) {
        uri = DataServerConfig.obtain().getUri(relativePath);
      }

      const uriWithId = uri + "/:id";
      const copyUriWithId = uriWithId + "/copy";
      defaultParams = _.assign({id: '@id'}, defaultParams);
      actions = _.merge({
        get: {
          method: 'GET',
          url: uriWithId
        },
        head: {
          method: 'HEAD',
          url: uriWithId
        },
        creativeSummary: {
          method: 'GET',
          url: uriWithId + '/creativesummary/?summaryType=:summaryType'
        },
        adnCreativeSummary: {
          method: 'GET',
          url: uriWithId + '/adncreativesummary'
        },
        updateTeamGroup: {
          method: 'POST',
          url: uriWithId + "/:teamId",
          transformRequest: HttpHelper.obtain($http).prependRequestTransform(function() {
            return {};
          }),
        },
        lineItem: {method: 'POST', url: uriWithId + '/lineitem'},
        copy: {method: 'POST', url: copyUriWithId},
        dimensionsCheck: {method: 'GET', url: uriWithId + '/dimensionsCheck', isArray: false},
        query: {method: 'GET', isArray: false, params: {includeHidden: false, includeActive: true, includeInactive: false}},
        info: {method: 'GET', url: uri + '/info', isArray: false, params: {includeHidden: false, includeActive: true, includeInactive: false}},
        save: {method: 'POST', url: uriWithId, params: {concise: '@concise'}},
        approve: {method: 'POST', url: uriWithId + '/approve'},
        bulkSave: {method: 'POST', params: {ignoreProduct: '@ignoreProduct'}},
        preview: {method: 'GET', url: uriWithId + '/preview', isArray: false},
        archive: {
          method: 'POST',
          url: uriWithId,
          transformRequest: HttpHelper.obtain($http).prependRequestTransform(function(data) {
            // only want the delete request to update the objectState and nothing else
            return {
              id: data.id,
              objectState: OBJECT_STATE.inactive
            };
          })
        },
        delete: {
          method: 'POST',
          url: uriWithId,
          transformRequest: HttpHelper.obtain($http).prependRequestTransform(function(data) {
            // only want the delete request to update the objectState and nothing else
            return {
              id: data.id,
              objectState: OBJECT_STATE.hidden
            };
          })
        },
        activate: {
          method: 'POST',
          url: uriWithId,
          transformRequest: HttpHelper.obtain($http).prependRequestTransform(function(data) {
            // only want the delete request to update the objectState and nothing else
            return {
              id: data.id,
              objectState: OBJECT_STATE.active
            };
          })
        },
        cancel: {
          method: 'POST',
          url: uriWithId
        }
      }, actions);

      let UserProfileSaver;
      let objRegister = function(obj) {
        if (!obj) {
          return;
        }
        $timeout(function() {
          // can be many gets -- want only the get for the main object that the page is on.
          const object1 = ObjectTypeFinder.findObjectFromUrl($state.current.name);
          const object2 = ObjectTypeFinder.findObjectFromUrl(obj.url);
          if (!!object1 && object1 === object2 && object1 !== OBJECT_TYPE.Workspace) {
            UserProfileSaver = UserProfileSaver || $injector.get("UserProfileSaver");
            UserProfileSaver.saveObj(obj, 'adnLastViewed', object2);
          }
        }, 500);
        return obj;
      };
      if (_.isArray(actions.get.transformResponse)) {
        actions.get.transformResponse.push(objRegister);
      } else {
        actions.get.transformResponse = HttpHelper.obtain($http).appendResponseTransform(objRegister);
      }

      let Resource = $resource(uri, defaultParams, actions);

      function queryFunction(actionsType, a1, a2, a3) {
        let params = {}, success, error;
        if (_.isFunction(a1)) {
          success = a1;
          error = a2;
        } else {
          params = a1;
          success = a2;
          error = a3;
        }

        if (_.isArray(params) || _.isArray(_.get(params, 'ids'))) {
          let ids = params.ids || params;

          // assume an array is a collection of IDs
          let itemLength = Math.min(ids.length, 301);
          params = _.merge({
            id: _.take(ids, itemLength).join(';'),
            pageSize: Math.min(ids.length, itemLength)
          }, _.isArray(params) ? {} : _.omit(params, 'ids'));
        }

        params = _.assign(_.omitBy(defaultParams, function(param) {
          return _.startsWith(param, '@');
        }), actionsType.params, params);

        let value = {$resolved: false, results: []}, promise;
        if (_.has(params, 'id') && _.trim(params.id).length === 0) {
          // don't run ID queries for zero IDs
          promise = $q.when({
            data: {
              results: [],
              page: 0,
              totalCount: 0
            }
          });
        } else {
          params.orderBy = params.orderBy || 'name';
          params.pageSize = params.pageSize || 301;

          promise = $http({
            method: actionsType.method,
            url: actionsType.url || uri,
            params: params,
            transformResponse: actionsType.transformResponse || $http.defaults.transformResponse
          });
        }

        value.$promise = promise.then(function(response) {
          _.assign(value, response.data, {$resolved: true});

          value.results = _.map(value.results, function(data) {
            return new Resource(data);
          });

          (success || angular.noop)(value);

          return value;
        }, function(response) {
          value.$resolved = true;

          (error || angular.noop)(response);

          return $q.reject(response);
        });

        return value;
      }

      // overwrite query to handle pagination
      Resource.query = function(a1, a2, a3) {
        return queryFunction(actions.query, a1, a2, a3);
      };
      Resource.info = function(a1, a2, a3) {
        return queryFunction(actions.info, a1, a2, a3);
      };

      Resource.prototype.updateValidationWarnings = function() {
        let resource = this;
        Resource.get({id: resource.id}).$promise.then(function(apiResource) {
          _.set(resource, 'validationWarnings', apiResource.validationWarnings);
        });
      };

      let localUserProfile;
      Resource.prototype.savePartial = function(props, endPoint, additionalProps) {
        localUserProfile = localUserProfile || $injector.get("localUserProfile");
        if (localUserProfile.isReadOnly()) {
          return $q.reject("Read-only is the sole option");
        }

        let data;
        if (props) {
          props = _.isArray(props) ? props : [props];
          props.unshift('id');
          data = _.pick(this, props);
        } else {
          data = angular.copy(this);
        }
        _.forEach(additionalProps || {}, function(val, key) {
          data[key] = val;
        });
        return Resource[endPoint || 'save'](data).$promise;
      };

      let theResourceName = resourceName;
      if (!theResourceName) {
        const pathNoSlash = relativePath.replace(/\//g, "");
        theResourceName = pathNoSlash.lastIndexOf("s") === pathNoSlash.length - 1 ? pathNoSlash.substring(0, pathNoSlash.length - 1) : pathNoSlash;
      }
      Resource.prototype.resourceName = theResourceName;

      return Resource;
    };
  });

export default MODULE_NAME;